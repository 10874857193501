.py-100{
  padding: 100px 0;
}
@media (max-width: 1399.9px){
  .py-100{
    padding: 70px 0;
  }
}
@media (max-width: 1199.9px){
  .py-100{
    padding: 50px 0;
  }
}
@media (max-width: 991.9px){
  .py-100{
    padding: 40px 0;
  }
}
@media (max-width: 767.9px){
  .py-100{
    padding: 30px 0;
  }
}
@media (max-width: 575.9px){
  .py-100{
    padding: 25px 0;
  }
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px gray;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f7cc42;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: gray;
}