@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap");
body {
  font-family: "Jost", "sans-serif" !important;
}
*{
  --bs-font-sans-serif: "Jost", sans-serif !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Jost", "sans-serif" !important;

}
.shadow1{box-shadow: 0px 4px 12px #8DD3BB26;}
.btn {
  background: #f7cc42;
  color: black;
  font-size: 14px;
  font-weight: 500;
  padding: 7px 17px;
  font-family: "Jost", sans-serif;
}
.offcanvas-header button.btn.btn-close {
  background: transparent var(--bs-btn-close-bg) center / 1em auto no-repeat;
}
.btn-lg {
  min-height: 60px;
  font-size: 15px;
}
.bdr_h {
  font-family: "Jost", sans-serif;
  font-weight: 600;
  font-size: 14px;
}
.bdr_h .ant-radio-wrapper {
  padding: 10px;
}
.bdr_h .ant-radio-wrapper-checked {
  background-color: rgb(247, 204, 66, 0.4);
  border-right: 1px solid #dedede;
}

.btn_header {
  border-radius: 22px;
}

.btn:hover {
  color: white;
  /* background-color: var(--bs-btn-hover-bg); */
  background-color: #f7cc42;
  border-color: #f7cc42;
}

/* .ant-checkbox-checked .ant-checkbox-inner {
  --background-color: #f7cc42;
  --border-color: #f7cc42;
} */

.form-check-input:checked {
  background-color: #f7cc42;
  border-color: #f7cc42;
}

.form-check-input:focus {
  box-shadow: none;
}

.btn_uni {
  background: #f7cc42 !important;
  color: #ffffff !important;
  font-size: 14px;
}

.btn_uni:hover {
  border: 1px solid #f7cc42;
  color: black !important;
}

.btn_uni2 {
  color: black !important;
  border: 2px solid #f7cc42;
  font-size: 14px;
  border-radius: 4px !important;
}

input[type="range"] {
  accent-color: #f7cc42;
}

.input:focus-visible {
  outline: none !important;
  box-shadow: 0px 0px 2px 0px rgb(59 107 153);
}

.loaderBox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.nav-tabs {
  --bs-nav-tabs-border-width: none !important;
}

/* .ant-dropdown.css-dev-only-do-not-override-11xg00t.ant-dropdown-placement-bottomRight {
  inset: 505.278px 77.347px auto auto !important;
} */
.ant-dropdown-menu .mainDiv.lastEle .paxCol:hover {
  background-color: transparent !important;
}
.mainDiv .minus_btn,
.mainDiv .plus_btn {
  display: flex;
  font-size: 18px;
  padding: 8px;
  border: 1px solid #f7cc42;
  border-radius: 4px;
  background: white;
  color: #f7cc42;
  justify-content: center;
  align-items: center;
}

.maonDiv,
.header_name {
  font-weight: 500;
  color: black;
}

.mainDiv .Counter {
  font-size: 20px;
  color: black;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
}

/* ==================antd checkbox css=================== */
.check_label,
.radio_label {
  /* margin-left: 6px; */
  font-weight: 400;
  font-size: 13px;
  display: flex;
  align-items: center;
}

:where(.css-dev-only-do-not-override-11xg00t).ant-radio-wrapper {
  margin-inline-end: 3px;
}

.checked {
  background: #fff0c8;
  border-radius: 10px;
  padding: 0px 5px;
}

.checked .ant-radio-wrapper span:nth-child(2) {
  font-weight: 600;
}

:where(.css-dev-only-do-not-override-11xg00t).ant-radio-wrapper
  .ant-radio-checked
  .ant-radio-inner {
  border-color: #f7cc42;
  background-color: #f7cc42;
}

:where(.css-dev-only-do-not-override-11xg00t).ant-radio-wrapper
  .ant-radio-checked::after {
  border: 1px solid #f7cc42;
}

:where(.css-dev-only-do-not-override-11xg00t).ant-radio-wrapper:hover
  .ant-radio-inner {
  border-color: #f7cc42;
}

/* ==================antd checkbox css=================== */

/* ===================rang css ========================= */

/* input[type=range]::-webkit-slider-runnable-track {
  background: red;
} */

.head .head_p {
  /* font-size: 14px; */
  /* color: #697488; */
  font-family: "Jost", sans-serif;
}

.bold_light {
  font-weight: 600;
}

/* ::-webkit-slider-runnable-track{
  background: #F7CC42;
  height: 6px;
  border: none !important;
} */
input[type="range"] {
  height: 22px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 7px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #f7cc42;
  border-radius: 14px;
  border: 0px solid #f7cc42;
}
input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0px 0px 1px #000000;
  border: 1px solid #f7cc42;
  height: 15px;
  width: 15px;
  border-radius: 16px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -4.5px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #f7cc42;
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 7px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #f7cc42;
  border-radius: 14px;
  border: 0px solid #000000;
}
input[type="range"]::-moz-range-thumb {
  box-shadow: 0px 0px 1px #000000;
  border: 1px solid #f7cc42;
  height: 15px;
  width: 15px;
  border-radius: 16px;
  background: #ffffff;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 7px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: #f7cc42;
  border: 0px solid #f7cc42;
  border-radius: 28px;
  box-shadow: 0px 0px 0px #000000;
}
input[type="range"]::-ms-fill-upper {
  background: #f7cc42;
  border: 0px solid #f7cc42;
  border-radius: 28px;
  box-shadow: 0px 0px 0px #000000;
}
input[type="range"]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 1px #000000;
  border: 1px solid #f7cc42;
  height: 15px;
  width: 15px;
  border-radius: 16px;
  background: #ffffff;
  cursor: pointer;
}
input[type="range"]:focus::-ms-fill-lower {
  background: #f7cc42;
}
input[type="range"]:focus::-ms-fill-upper {
  background: #f7cc42;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgb(157 157 157) !important;
}

@media screen and (min-device-width: 200px) and (max-device-width: 768px) {
  .btn_header {
    padding: 2px 15px;
  }

  .about_usImg,
  .home_navbar {
    position: initial !important;
  }

  .navbar-toggler {
    border-color: black !important;
  }

  .navbar-toggler-icon {
    color: black !important;
  }
/* 
  a.nav-link,
  a.nav-link.active {
    color: black !important;
  } */

  .mask {
    border-radius: 5px !important;
  }

  /* ========================================================== */
  .line::after {
    display: none;
  }

  .batch {
    top: 0px !important;
    font-size: 7px !important;
  }

  .tbl_header th {
    background: #f7f7f7;
    padding: 10px 3px;
  }

  /* ========================================================== */
}
